import { useEffect, useState } from "react";
import Helmet from "react-helmet";
import FlowPowerBill from "./flow/powerbill";
import HomeOwner from "./flow/homeowner";
import Provider from "./flow/provider";
import RoofShade from "./flow/roofshade";
import Address from "./flow/address";
import AddressChange from "./flow/addressChange";
import Name from "./flow/name";
import Email from "./flow/email";
import Tel from "./flow/tel";
import Confirm from "./flow/confirm";
import Quote from "./flow/quote"

const Home = ({progress, setProgress}) => {
  const [step, setStep] = useState("Quote");
  const [bill, setBill] = useState(0);
  const [formData, setFormData] = useState({
    ownHome: "own",
    electricBill: "",
    provider: "",
    homeType: "",
    roofShade: 0,
    zipCode: "",
    city: "",
    state: "",
    address: "",
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    excitedType: "",
    ipAddress: "",
    fbclid: "",
    cid: "",
  });

  return (
    <div className="flex flex-row justify-center">
      <Helmet>
        <script>{`window.fbq = fbq`}</script>
      </Helmet>

      {step === "Quote" && (
        <Quote
          formData={formData}
          setProgress={setProgress}
          setStep={setStep}
        />
      )}
      {step === "FlowPowerBill" && (
        <FlowPowerBill
          formData={formData}
          setProgress={setProgress}
          bill={bill}
          setBill={setBill}
          setStep={setStep}
        />
      )}
      {step === "HomeOwner" && (
        <HomeOwner
          formData={formData}
          setProgress={setProgress}
          setStep={setStep}
        />
      )}
      {step === "Provider" && (
        <Provider
          formData={formData}
          setProgress={setProgress}
          setStep={setStep}
        />
      )}
      {step === "RoofShade" && (
        <RoofShade
          formData={formData}
          setProgress={setProgress}
          setStep={setStep}
        />
      )}
      {step === "Address" && (
        <Address
          formData={formData}
          setProgress={setProgress}
          setStep={setStep}
        />
      )}
      {step === "AddressChange" && (
        <AddressChange
          formData={formData}
          setProgress={setProgress}
          setStep={setStep}
        />
      )}
      {step === "Name" && (
        <Name formData={formData} setProgress={setProgress} setStep={setStep} />
      )}
      {step === "Email" && (
        <Email formData={formData} setProgress={setProgress} setStep={setStep} />
      )}
      {step === "Tel" && (
        <Tel formData={formData} setProgress={setProgress} setStep={setStep} />
      )}
      {step === "Confirm" && <Confirm />}
    </div>
  );
};

export default Home;
