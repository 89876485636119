export default function ProgressBar({ state }) {
  return (
    <div className="md:w-[90px] w-1/2" aria-hidden="true">
      <div className="overflow-hidden rounded-sm bg-[white]">
        <div
          className="h-[18px] bg-themeGreenText1"
          style={{ width: (100 * state) / 8 + "%" }}
        />
      </div>
    </div>
  );
}
