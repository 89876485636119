import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";

const AddressChange = ({ formData, setProgress, setStep }) => {
  useEffect(() => {
    setProgress(5);
  }, [setProgress]);
  const [city, setCity] = useState(formData.city);
  const [zip, setZip] = useState(formData.zipCode);
  const [town, setTown] = useState(formData.state);
  const [cityWarning, setCityWarning] = useState("");
  const [zipWarning, setzipWarning] = useState("");
  const navigate = useNavigate();
  const handleKeyPress = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };
  const onLinkHandler = () => {
    if (city === "") {
      setCityWarning("Please enter city");
      return;
    }
    if (zip === "") {
      setzipWarning("Please enter zip");
      return;
    }
    formData.city = city;
    formData.zipCode = zip;
    formData.state = town;
    setStep('Address');
  };
  return (
    <div className="flex flex-col items-center justify-center md:px-3 px-5">
      <div className="font-bold md:text-[33px] text-lg text-themeDarkText1 text-center pt-3">
        What's your property address?
      </div>
      <p className="mt-1 text-themeGreenText1 text-[18px] text-center">
        For verification only. We do not mail.
      </p>
      <div className="mt-[30px] md:w-80 w-full relative flex flex-col justify-center">
        <TextField
          id="outlined-basic"
          label="city"
          variant="outlined"
          className="w-full"
          defaultValue={formData.city}
          value={city}
          onChange={(e) => {
            setCity(e.target.value);
          }}
        />
        {<p className="text-themeText1 mb-3">{cityWarning}</p>}
        <div className="relative mt-[10px]">
          <select
            className="border-themeDarkBorder1 cursor-pointer block p-4 pr-8 leading-tight focus:outline-none text-themeDarkText3 text-[14px] rounded-lg w-full appearance-none border-gray-400 border"
            id="select-option"
            value={town}
            defaultValue={formData.state}
            onChange={(e) => setTown(e.target.value)}
          >
            <option value="Alabama">Alabama</option>
            <option value="Alaska">Alaska</option>
            <option value="Arizona">Arizona</option>
            <option value="Arkansas">Arkansas</option>
            <option value="California">California</option>
            <option value="Colorado">Colorado</option>
            <option value="Connecticut">Connecticut</option>
            <option value="Delaware">Delaware</option>
            <option value="Destrict of Columbia">Destrict of Columbia</option>
            <option value="Florida">Florida</option>
            <option value="Georgia">Georgia</option>
            <option value="Hawaii">Hawaii</option>
            <option value="Idaho">Idaho</option>
            <option value="Illinois">Illinois</option>
            <option value="Indiana">Indiana</option>
            <option value="Iowa">Iowa</option>
            <option value="Kansas">Kansas</option>
            <option value="Kentucky">Kentucky</option>
            <option value="Louisiana">Louisiana</option>
            <option value="Maine">Maine</option>
            <option value="Maryland">Maryland</option>
            <option value="Massachusetts">Massachusetts</option>
            <option value="Michigan">Michigan</option>
            <option value="Minnesota">Minnesota</option>
            <option value="Mississippi">Mississippi</option>
            <option value="Missouri">Missouri</option>
            <option value="Montana">Montana</option>
            <option value="Nebraska">Nebraska</option>
            <option value="Nevada">Nevada</option>
            <option value="New Hampshire">New Hampshire</option>
            <option value="New Jersey">New Jersey</option>
            <option value="New Mexico">New Mexico</option>
            <option value="New York">New York</option>
            <option value="North Carolina">North Carolina</option>
            <option value="North Dakota">North Dakota</option>
            <option value="Ohio">Ohio</option>
            <option value="Oklahoma">Oklahoma</option>
            <option value="Oregon">Oregon</option>
            <option value="Pennsylyania">Pennsylyania</option>
            <option value="Rhode Island">Rhode Island</option>
            <option value="South Carolina">South Carolina</option>
            <option value="South Dakota">South Dakota</option>
            <option value="Tennessee">Tennessee</option>
            <option value="Texas">Texas</option>
            <option value="Utah">Utah</option>
            <option value="Vermont">Vermont</option>
            <option value="Virginia">Virginia</option>
            <option value="Washington">Washington</option>
            <option value="West Virginia">West Virginia</option>
            <option value="Wisconsin">Wisconsin</option>
            <option value="Wyoming">Wyoming</option>
          </select>
          <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M15 8L10 13L5 8"
                stroke="#89888E"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
        </div>
        <div
          className="relative mb-3 w-full mt-[10px]"
          data-te-input-wrapper-init
        >
          <TextField
            id="outlined-basic"
            label="zip"
            variant="outlined"
            className="w-full"
            value={zip}
            onChange={(e) => {
              setZip(e.target.value);
            }}
            defaultValue={formData.zipCode}
            onKeyPress={handleKeyPress}
          />
          {<p className="text-themeText1 mb-3">{zipWarning}</p>}
        </div>
        <button
          type="button"
          className="inline-flex items-center justify-center rounded border border-transparent bg-themeGreenText1 hover:bg-themeBgColor1 md:text-[24px] text-lg mt-[30px] md:px-24 md:py-3 sm:px-16 sm:py-2 px-10 py-2 font-medium text-[white] shadow-sm focus:outline-none"
          onClick={onLinkHandler}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default AddressChange;
