import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
import { useLocation } from "react-router-dom";

const Quote = ({ formData, setProgress, setStep }) => {
  useEffect(() => {
    setProgress(0);
  }, [setProgress]);
  const [zip, setZip] = useState("");
  const [warning, setWarning] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const onLinkHandler = (e) => {
    const containsLettersAndNumbers = /^\d{5}$/.test(zip);
    if (containsLettersAndNumbers) {
      formData.fbclid = searchParams.get("fbclid");
      formData.cid = searchParams.get("cid");
      formData.zipCode = zip;
      setStep('FlowPowerBill');
    } else {
      setZip("");
      setWarning(true);
    }
  };
  return (
    <div className="flex flex-row justify-center">
      <div className="flex lg:flex-row flex-col px-12 justify-center xl:w-[1200px] lg:w-[1000px]">
        <div className="md:px-8 md:py-4 px-4 py-1 xl:w-[67%] lg:w-[65%]">
          <p className="xl:text-[55px] lg:text-[40px] md:text-[32px] text-[20px] lg:text-right text-center font-bold mt-4">
            USA Program Offers Solar For $0
          </p>
        </div>
        <div className="w-70 border md:px-8 md:py-4 px-4 py-1 border-[#e2e2e2] xl:w-[33%] lg:w-[35%] md:w-[600px] md:mt-0 mt-4">
          <div className="font-bold md:text-[33px] text-lg text-themeGreenText1 text-center">
            FREE Expert Solar Quote
          </div>
          <p className="mt-2 text-themeDarkText1 md:text-[15px] text-sm text-center">
            See if you qualify for incentives in your area and get solar with
            zero upfront costs!
          </p>
          <div className="mt-[30px] flex flex-col gap-x-3 w-full justify-center items-center">
            <div className="flex flex-col justify-center w-full">
              <TextField
                id="outlined-basic"
                label="Zip Code"
                variant="outlined"
                className="w-full"
                value={zip}
                onChange={(e) => {
                  setZip(e.target.value.replace(/[^0-9]/g, "").slice(0, 5));
                  setWarning(false);
                }}
              />
              {warning && (
                <p className="text-themeText1 md:text-md text-sm">
                  Please enter a valid zip code
                </p>
              )}

              <button
                type="button"
                onClick={onLinkHandler}
                className="inline-flex items-center justify-center rounded border border-transparent bg-themeGreenText1 hover:bg-themeBgColor1 md:text-[24px] text-lg mt-[30px] md:px-24 md:py-3 sm:px-16 sm:py-2 px-8 py-1 font-medium text-[white] shadow-sm focus:outline-none whitespace-nowrap"
              >
                FREE QUOTE
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quote;
