import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
const RoofShade = ({ formData, setProgress, setStep }) => {
  useEffect(() => {
    setProgress(4);
  }, [setProgress]);
  const navigate = useNavigate();
  const cardButtonCss =
    "md:w-[190px] md:h-[190px] w-32 h-32 flex flex-col justify-center items-center border border-themeDarkBorder1 cursor-pointer hover:-translate-y-4 rounded-md duration-300 hover:border-themeGreenText1";
  return (
    <div className="flex flex-col items-center justify-center px-3">
      <div className="font-bold md:text-[33px] text-lg text-themeDarkText1 text-center mt-4">
        How much roof shade do you have?
      </div>
      <div className="mt-[30px] md:flex md:flex-row grid grid-cols-2 md:gap-x-3 gap-3">
        <button
          className={`${cardButtonCss}`}
          onClick={() => {
            setStep('Address');
            formData.roofShade = 1;
          }}
        >
          <img src="/img/noshade.webp" className="w-20" alt=""></img>
          <p className="mt-4 text-center">No Shade</p>
        </button>
        <button
          className={`${cardButtonCss}`}
          onClick={() => {
            setStep('Address');
            formData.roofShade = 2;
          }}
        >
          <img src="/img/littleshade.webp" className="w-20" alt=""></img>
          <p className="mt-4 text-center">A Little Shade</p>
        </button>
        <button
          className={`${cardButtonCss}`}
          onClick={() => {
            setStep('Address');
            formData.roofShade = 3;
          }}
        >
          <img src="/img/alotshade.webp" className="w-20" alt=""></img>
          <p className="mt-4 text-center">A Lot Of Shade</p>
        </button>
        <button
          className={`${cardButtonCss}`}
          onClick={() => {
            setStep('Address');
            formData.roofShade = 4;
          }}
        >
          <img src="/img/uncertain.webp" className="w-20" alt=""></img>
          <p className="mt-4 text-center">Uncertain</p>
        </button>
      </div>
    </div>
  );
};

export default RoofShade;
