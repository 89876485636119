import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { isValidPhoneNumber } from "react-phone-number-input";
import { setWholeInfomation } from "../../utils/function";

const Tel = ({ formData, setProgress, setStep }) => {
  useEffect(() => {
    setProgress(8);
  }, [setProgress]);
  const [phone, setPhone] = useState("");
  const [warning1, setWarning1] = useState("");
  const navigate = useNavigate();

  const onLinkHandler = (e) => {
    if (!isValidPhoneNumber("+" + phone)) {
      setWarning1("Please enter a valid phone address");
      return;
    }

    if (typeof window.fbq === "function") {
      window.fbq("track", "Lead");
    }
    const numericValue = phone.replace(/\D/g, "").slice(1);
    const formattedValue = `(${numericValue.slice(0, 3)}) ${numericValue.slice(
      3,
      6
    )}-${numericValue.slice(6, 10)}`;

    formData.phoneNumber = formattedValue;
    fetch("https://api.ipify.org/?format=json")
      .then((response) => response.json())
      .then((data) => {
        formData.ipAddress = data.ip;
        setWholeInfomation(formData);
        setStep('Confirm');
      });
  };

  const handlePhoneChange = (value) => {
    setPhone(value);
  };

  return (
    <div className="flex flex-col items-center justify-center px-3">
      <div className="flex flex-col items-center">
        <div className="font-bold md:text-[33px] text-lg text-themeDarkText1 max-w-[600px] text-center mt-4">
          You've Been Matched With Solar Experts In Your Area!
        </div>
        <p className="mt-2 text-themeGreenText1 md:text-[18px] text-md text-center">
          Complete this final step to see your savings.
        </p>
        <div className="mt-[30px] flex flex-col gap-x-3 items-center justify-center">
          <PhoneInput
            country={"us"}
            onChange={handlePhoneChange}
            inputExtraProps={{
              pattern: "\\(\\d{3}\\) \\d{3}-\\d{4}",
            }}
          />
          {<p className="text-themeText1">{warning1}</p>}
          <button
            type="button"
            onClick={onLinkHandler}
            className="inline-flex items-center justify-center rounded border border-transparent bg-themeGreenText1 hover:bg-themeBgColor1 md:text-[24px] text-lg mt-[30px] md:px-24 md:py-3 sm:px-16 sm:py-2 px-10 py-2 font-medium text-[white] focus:outline-none sm:w-72 w-full whitespace-nowrap"
          >
            Get Free Quote
          </button>
        </div>

        <p className="text-[11px] text-[#888888] mt-8 lg:w-[500px] md:w-[400px] sm:w-80 text-center">
          By clicking the “SUBMIT” button, you authorize US Solar Savings and up
          to 4 Home Services / Solar Partners to call you and send you
          pre-recorded messages and text message at the number you entered
          above, using an autodialer, with offers about their products
        </p>
      </div>
    </div>
  );
};

export default Tel;
