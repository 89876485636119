import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
const Name = ({ formData, setProgress, setStep }) => {
  useEffect(() => {
    setProgress(6);
  }, [setProgress]);
  const [firstName, setFirstName] = useState("");
  const [warning1, setWarning1] = useState("");
  const [lastName, setLastName] = useState("");
  const [warning2, setWarning2] = useState("");
  const navigate = useNavigate();
  const isValidInput = (inputValue) => {
    const regex = /^[a-zA-Z\s]+$/;
    return regex.test(inputValue);
  };
  const onLinkHandler = (e) => {
    if (firstName === "") {
      setWarning1("Please enter your first name");
      return;
    }
    if (!isValidInput(firstName)) {
      setWarning1(
        "Your first name may only contain letters, hyphens, or spaces"
      );
      return;
    }
    if (lastName === "") {
      setWarning2("Please enter your last name");
      return;
    }
    if (!isValidInput(lastName)) {
      setWarning2(
        "Your last name may only contain letters, hyphens, or spaces"
      );
      return;
    }
    formData.firstName = firstName;
    formData.lastName = lastName;
    setStep('Email');
  };
  return (
    <div className="flex flex-col items-center justify-center px-3">
      <div className="font-bold md:text-[33px] text-lg text-themeDarkText1 mt-4">
        What's your name?
      </div>
      <p className="mt-1 text-themeGreenText1 text-[18px] text-center">
        Personal Information Is Safe & Secure.
      </p>
      <div className="mt-[30px] flex flex-col gap-x-3 md:w-80">
        <TextField
          id="outlined-basic"
          label="First Name"
          variant="outlined"
          className="w-full"
          value={firstName}
          onChange={(e) => {
            setFirstName(e.target.value);
            setWarning1("");
          }}
        />
        {<p className="text-themeText1 mb-3">{warning1}</p>}
        <TextField
          id="outlined-basic"
          label="Last Name"
          variant="outlined"
          className="w-full"
          value={lastName}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
        />
        {<p className="text-themeText1 mb-3">{warning2}</p>}
        <button
          type="button"
          onClick={onLinkHandler}
          className="inline-flex items-center justify-center rounded border border-transparent bg-themeGreenText1 hover:bg-themeBgColor1 md:text-[24px] text-lg mt-[30px] md:px-24 md:py-3 sm:px-16 sm:py-2 px-10 py-2 font-medium text-[white] shadow-sm focus:outline-none"
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default Name;
