import * as React from "react";
import { Slider } from "@mui/material";

const BillSlider = ({ bill, setBill }) => {
  return (
    <div className="lg:w-[500px] md:w-80 w-56 m-auto">
      <Slider
        defaultValue={0}
        onChange={(e) => {
          setBill(e.target.value);
        }}
        value={bill}
        step={1}
        min={0}
        max={3}
        aria-label="Default"
        valueLabelDisplay="auto"
      />
    </div>
  );
};

export default BillSlider;
