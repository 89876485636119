import ProgressBar from "./progrssbar";
const Header = ({ progress }) => (
  <div className="w-full flex md:px-[200px] md:py-[25px] sm:px-20 sm:py-3 px-4 py-8 justify-between items-center bg-[#bcd666] md:flex-row flex-col gap-4">
    <div className="flex items-center justify-center">
      <img src="/img/logo.webp" className="w-[250px]" alt=""></img>
    </div>
    <ProgressBar state={progress} />
  </div>
);

export default Header;
