import { Link } from "react-router-dom";
const Footer = () => {
  const labelCss = "text-[#717171] text-[11px]";
  return (
    <div>
      <div className="h-[200px] bg-footer opacity-70"></div>
      <div className="py-[20px] flex w-full md:flex-row flex-col justify-center bg-[#CCE678] gap-2">
        <Link className="px-1 text-center" to="#">
          <p className={`${labelCss}`}>
            © {new Date().getFullYear()} Energybillcruncher
          </p>
        </Link>
        <Link className="px-1 text-center" to="#">
          <p className={`${labelCss}`}>Terms & Disclosures</p>
        </Link>
        <Link className="px-1 text-center" to="#">
          <p className={`${labelCss}`}>Do Not Sell My Info</p>
        </Link>
        <Link className="px-1 text-center" to="#">
          <p className={`${labelCss}`}>Contact Us</p>
        </Link>
      </div>
    </div>
  );
};

export default Footer;
