export function setWholeInfomation(formData) {
  let request = new URLSearchParams();
  request.append(
    "event_id",
    `${Math.floor(Math.random() * 100000) + 1000000}_${
      Math.floor(Math.random() * 900000000) + 100000000
    }_enter`
  );
  request.append("originDomain", "solaire.affiliate.com");
  request.append("leadBy", "mehdi");
  request.append("offer", "Solar");
  request.append("lp_campaign_id", "6323bacb64a7e");
  request.append("lp_campaign_key", "NLJRF6rmy3Dd72nHM8Vb");
  request.append("electricity_provider", "unknown");
  request.append(
    "tcpa_text",
    "By clicking the “SUBMIT” button, you authorize our partners to call you and send you pre-recorded messages and text message at the number you entered above, using an autodialer, with offers about their products or services, even if your phone number is on any national or state “Do Not Call” list. Message and data rates may apply. Your consent here is not based on a condition of purchase."
  );
  request.append("tcpa_optin", "Yes");
  request.append(
    "landing_page",
    "https://magreno.leadshook.io/survey/solar-panels-v1"
  );
  request.append("subid", "12");
  request.append("url", "https://www.solarprogram2023.com/survey/solar");
  request.append("lp_s2", "12");
  request.append("first_name", formData.firstName);
  request.append("last_name", formData.lastName);
  request.append("phone_home", formData.phoneNumber);
  request.append("homeowner", formData.ownHome === "own" ? "own" : "rent");
  request.append("average_monthly_electric_bill", formData.electricBill);
  request.append("email_address", formData.email);
  request.append("address", formData.address);
  request.append("city", formData.city);
  request.append("state", formData.state);
  request.append("zip_code", formData.zipCode);
  request.append("ip_address", formData.ipAddress);
  request.append("credit", "");
  request.append(
    "roof_shade",
    formData.roofShade === 1
      ? "No Shade"
      : formData.roofShade === 2
      ? "Partial Shade"
      : formData.roofShade === 3
      ? "Full Shade"
      : "Not Sure"
  );
  request.append("user_agent", "");
  request.append("type_of_home", "Single Family Home");
  request.append("average_monthly_electric", formData.electricBill);
  request.append(
    "homeowner_px",
    formData.ownHome === "own" ? "Owned" : "Rented"
  );
  request.append(
    "roof_shade_px",
    formData.roofShade === 1
      ? "Full sun"
      : formData.roofShade === 2
      ? "Partial sun"
      : formData.roofShade === 3
      ? "Mostly Shade"
      : "Not Sure"
  );
  request.append("fbclid", formData.fbclid);
  request.append("headline", "offer33");
  request.append("leadid_token", window.LeadiD.token);
  request.append("jornaya_lead_id", window.LeadiD.token);
  request.append(
    "trusted_form_cert_id",
    `https://cert.trustedform.com/${window.trustedForm.id}`
  );
  request.append("client_user_agent", navigator.userAgent);
  // request.append("event_id", "1053850_355133726_enter");
  request.append("cid", formData.cid);

  console.log(formData);
  fetch("https://bingoleads.leadspediatrack.com/post.do", {
    method: "POST",
    body: request,
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
  })
    .then((response) => {
      response.json();
    })
    .then((data) => {
      alert("Successfully Submitted!!!");
    })
    .catch((error) => console.error(error));
}
