import { Link, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import TextField from "@mui/material/TextField";
const Address = ({ formData, setProgress, setStep }) => {
  useEffect(() => {
    setProgress(5);
  }, [setProgress]);
  const [address, setAddress] = useState("");
  const [warning, setWarning] = useState(false);
  const navigate = useNavigate();
  const onLinkHandler = (e) => {
    const containsLettersAndNumbers =
      /^(?=.*[0-9])(?=.*[a-zA-Z])[a-zA-Z0-9\s,]+$/.test(address);
    if (containsLettersAndNumbers) {
      formData.address = address;
      setStep('Name');
    } else {
      setAddress("");
      setWarning(true);
    }
  };
  return (
    <div className="flex flex-col items-center justify-center px-3">
      <div className="font-bold md:text-[33px] text-lg text-themeDarkText1 text-center mt-4">
        What's your property address?
      </div>
      <p className="mt-1 text-themeGreenText1 md:text-[18px] text-md text-center">
        For verification only. We do not mail.
      </p>
      <div className="mt-[30px] flex flex-col gap-x-3 w-full justify-center items-center">
        <div className="flex flex-col justify-center md:w-80 w-60">
          <TextField
            id="outlined-basic"
            label="Street Address"
            variant="outlined"
            className="w-full"
            value={address}
            onChange={(e) => {
              setAddress(e.target.value);
              setWarning(false);
            }}
          />
          {warning && (
            <p className="text-themeText1 md:text-md text-sm">
              Your address must contain letters and numbers
            </p>
          )}
          <p className="mt-1 text-[11px] text-themeDarkText2">
            {formData.city}, {formData.state}, {formData.zipCode}&nbsp;
            <button onClick={()=>{setStep('AddressChange');}} className="text-themeBlueText1">
              Change
            </button>
          </p>
          <button
            type="button"
            onClick={onLinkHandler}
            className="inline-flex items-center justify-center rounded border border-transparent bg-themeGreenText1 hover:bg-themeBgColor1 md:text-[24px] text-lg mt-[30px] md:px-24 md:py-3 sm:px-16 sm:py-2 px-10 py-2 font-medium text-[white] shadow-sm focus:outline-none md:w-80 w-30"
          >
            Continue
          </button>
        </div>
      </div>
    </div>
  );
};

export default Address;
